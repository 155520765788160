import * as types from './actionTypes'
import * as commonTypes from './../common/actionTypes'

import _ from 'lodash'

import api from '../apiConfig'
import axios from 'axios'
import moment from 'moment'
import { localUrlGeo, mapKey } from '../configLocal'
import { momentTzHelper } from '../../helpers/momentTz'
import { store } from '@/redux'

const rq = api.routes
const run = api.run
const def = api.def
const runV2 = api.runV2

// ПОЛУЧАЕМ СПИСОК ВСЕХ РАУТОВ
export function getRoutes(session_id) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.get(
      `route-groups?season_id=${session_id}&expand=routes.riderscount,routes.stopscount`
    )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.ROUTE_GET_ITEMS,
            payload: response.data,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// ПОЛУЧАЕМ СПИСОК ВСЕХ АКТИВНЫХ - РАУТОВ
export function getActiveRuns() {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.get(`routes?expand=routegroup&is_active=1`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.ROUTE_GET_ITEMS_GPS,
            payload: response.data,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// Редактирование группы для раутов(имя)
export function editGroup(id, title) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.put(`route-groups/${id}`, { title: title })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.ROUTE_GROUP_EDIT,
            payload: response.data,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// Редактирование группы для раутов(имя)
export function editGroup_socket(response) {
  return function (dispatch) {
    dispatch({
      type: types.ROUTE_GROUP_EDIT,
      payload: response,
    })
  }
}

// add groups
export function addGroup(data) {
  return function (dispatch) {
    rq.put(`routes/` + data.id, JSON.stringify(data))
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.ROUTE_GROUP_ADD,
            payload: response.data,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function editItem(item) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.put('routes/' + item.id, JSON.stringify(item))
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.ROUTE_EDIT_ITEM,
            payload: response.data,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          console.warn('error ' + item.id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function editItem_socket(response) {
  return function (dispatch) {
    dispatch({
      type: types.ROUTE_EDIT_ITEM,
      payload: response,
    })
  }
}

export function editItemReload(item) {
  return function (dispatch) {
    rq.put('routes/' + item.id, JSON.stringify(item))
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.ROUTE_GROUP_ADD,
            payload: response.data,
          })
        } else {
          console.warn('error ' + item.id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function geoCoderAddress(text, keys) {
  return function (dispatch) {
    axios
      .get(localUrlGeo + '/geocoding?query=' + text, {
        headers: {
          Authorization: `Bearer ${mapKey}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          response.data.keys = []
          response.data.deleteId = 0

          dispatch({
            type: types.GEOCODER_ADDRESS,
            payload: response.data,
            keys: keys,
          })
        } else {
          console.warn('error ')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// Добавлеяем новый РАУТ ( и сразу добавляем в маршрут ДЕПО СТАРТ)
export function addItem(item, session_id) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    run
      .post('routes', JSON.stringify({ ...item, season_id: session_id }))
      .then(function (response) {
        if (response.status === 201) {
          run
            .post(`/route-points/create-depot?expand=routePointDepot`, {
              depot_id: response.data.bus_depot_id,
              route_id: response.data.id,
              time: '13:00',
            })
            .then((responseDEP) => {
              if (responseDEP.status === 200) {
                dispatch({
                  type: types.ROUTE_ADD_ITEM,
                  payload: response.data,
                })
              }
              dispatch({ type: commonTypes.PENDING_FINISH })
            })
            .catch((error) => {
              dispatch({
                type: types.ROUTE_ADD_ITEM,
                payload: response.data,
              })

              console.warn(error)
            })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// Добавлеяем новый РАУТ
export function addItem_socket(response) {
  return function (dispatch) {
    dispatch({
      type: types.ROUTE_ADD_ITEM,
      payload: response,
    })
  }
}

// Удаляем РАУТ
export function removeItem(ids) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    ids.forEach((id) => {
      rq.delete('routes/' + id)
        .then(function (response) {
          if (response.status === 204) {
            dispatch({
              type: types.ROUTE_REMOVE_ITEM,
              payload: id,
            })
            dispatch({ type: commonTypes.PENDING_FINISH })
          } else {
            console.warn('status <>204 ' + id)
          }
        })
        .catch(() => {
          console.warn('catch', id)
        })
    })
  }
}

export function removeItem_socket(id) {
  return function (dispatch) {
    dispatch({
      type: types.ROUTE_REMOVE_ITEM,
      payload: id,
    })
  }
}

/**********************************************
 ***************RUN_BLOCK***********************
 ***********************************************/

// проверка на валидный адресс
export function validAddress(val) {
  return function (dispatch) {
    rq.get(`addresses/valid?name=` + val)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_VALID_ADDRESS,
            payload: { data: response.data },
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        dispatch({
          type: types.RUN_VALID_ADDRESS,
          payload: { error: 'invalid address', valid: false, code: '404' },
        })
      })
  }
}

// редактирование позиции и времени
export function optimizeRun(route, range, company) {
  range = [range[0] + 1, range[1] + 1]
  let prev
  let skip = 0
  let points = route.points.map((point, i) => {
    if (point.position >= range[0] && point.position <= range[1]) {
      prev = point
      return {
        lat: point.address && point.address.lat ? point.address.lat : null,
        lon: point.address && point.address.lon ? point.address.lon : null,
        id: point.routePointRoute.id,
        position: point.position,
      }
    }
  })
  range[1] = range[1] - skip
  points = _.compact(points)

  let set = {
    curbside_pickup: route.curbside_pickup,
    no_uturns: route.no_uturns,
  }

  if (route.use_default_rules && company) {
    set.curbside_pickup = company.curbside_pickup
    set.no_uturns = company.no_uturns
  }

  let resp = {
    points,
    params: {
      source: 'first',
      destination: 'last',
    },

    // get DEF sessings (from company)
    no_uturns: set.no_uturns,
    curbside_pickup: set.curbside_pickup,
  }

  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    def
      .post(localUrlGeo + '/trip', JSON.stringify(resp))
      .then((response) => {
        if (response.status === 200) {
          /* check rule TRANSFER POINT */
          let error = checkTransferRule(route, range, response.data)
          if (error) {
            dispatch({
              type: types.ERROR,
              runId: route.id,
            })
            return false
          }
          /* end */

          let step = range[0] - 1
          let points = response.data.points.map((x) => {
            return { id: x.id, position: x.point_index + 1 + step }
          })

          if (range[0] > 1) {
            let addArr = route.points.slice(0, step)
            addArr = addArr.map((x) => {
              return { id: x.routePointRoute.id, position: x.position }
            })
            points.splice(0, 0, ...addArr)
          }

          if (range[1] > points.length - 1) {
            let addArr = route.points.slice(range[1])
            addArr.forEach((x) =>
              points.push({ id: x.routePointRoute.id, position: x.position })
            )
          }
          rq.put(
            `/route-cruds/${route.id}`,
            JSON.stringify({ routePointRoute: points })
          ).then((response) => {
            if (response.status === 200) {
              dispatch({ type: commonTypes.PENDING_FINISH })
              dispatch({
                type: types.RUN_RELOAD,
                payload: response.data,
                runId: route.id,
                optimize: true,
                // position: position
              })
            }
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

let checkTransferRule = function (route, range, response) {
  let transferPoint = {}
  let arr = route.points.map((x) => {
    if (x.position >= range[0] && x.position <= range[1]) {
      if (x.type === 5) {
        transferPoint = x
      }
      return x
    }
  })
  arr = _.compact(arr)

  let error = false

  if (transferPoint.hasOwnProperty('position')) {
    let oldPositionTp = transferPoint.position
    let newPositionTp =
      (response.points || []).find(
        (x) => x.id === transferPoint.routePointRoute.id
      ).point_index + 1

    transferPoint.routePointTransferChild.forEach((child) => {
      let childRId = (route.points || []).find((x) => {
        if (x.type === 1 && x.object.id === child.childAddress.child_id) {
          return x
        }
        if (x.type === 2) {
          return (x.routePointChildren || []).find(
            (y) => child.childAddress.child_id === y.child_id
          )
        }
      })

      if (!childRId) return false
      let newPositionPoint = (response.points || []).find(
        (x) => x.id === childRId.routePointRoute.id
      )

      if (newPositionPoint) {
        if (newPositionPoint.point_index + 1 > newPositionTp) {
          error = true
        }
      }
    })
  }

  return error
}

// получаю всю информацию по маршруту , собираю вэйпоинты, получаю OSRM
export function getRunDetails(runId, defSettings, stopOverMode = false) {
  return async function (dispatch) {
    try {
      const host = store.getState().auth.company?.osrm_host
      dispatch({
        type: types.ROUTE_GET_DETAILS_LOADING,
        payload: true,
      })
      dispatch({ type: commonTypes.PENDING_START })
      const response = await rq.get(
        `/routes/${runId}?expand=points.routePointChildren.childaddress,
      points.routePointChildren.childaddress.child,points.routePointRoute,
      points.routePointTransferChild.childAddress.child,points.routePointRoutes,
      %20%20%20%20%20%20points.routePointChildren.childaddress.address`,
        { reload: true }
      )
      if (response.status === 200) {
        // delete routePointChildren without full_name child
        //         let data = response.data
        let data = {
          ...response.data,
          points: response.data.points.map((point) => ({
            ...point,
            ...(point.object ? {} : { withProblems: true }),
            routePointChildren: point.routePointChildren.map((item) => {
              if (!item.childaddress.child) {
                return { withProblems: true, ...item }
              } else {
                return item
              }
            }),
          })),
          //TODO удаление из ответа с сервера поинта без lat lon

          // .filter(
          //   (point) =>
          //     isConvertibleToNumber(point.address.lat) ||
          //     isConvertibleToNumber(point.address.lon)
          // ),
        }

        if (data.points.length >= 2) {
          let pointsSort = _.sortBy(data.points, 'position')
          let Data = _.filter(pointsSort, (value) => {
            if (value.address && value.address.lat) {
              return value
            }
          })

          let some = Data.some((x) => x.isAnchor)
          let points = Data.map((point, i) => {
            let curbside_pickup
            if (point.defaultSettings) {
              curbside_pickup = data.curbsidePickup
            } else {
              curbside_pickup = point.curbsidePickup
            }

            let time = 0
            if (point.isAnchor) {
              let d = moment().format('YYYY-MM-DD')
              time = moment.utc(`${d} ${point.routeTime}`).unix()
            }

            let is_morning = data.is_morning
            let hourStart = is_morning === 1 ? 7 : 12

            if (!some && !i) {
              let d = moment().format('YYYY-MM-DD')
              let timeN = moment().unix() // время сейчас no UTC
              let localUTCOffset = momentTzHelper()(
                timeN * 1000,
                data.time_zone
              )._offset

              if (localUTCOffset < 0) {
                hourStart = Math.abs(localUTCOffset / 60) + hourStart
              } else {
                hourStart = hourStart - localUTCOffset / 60
              }

              time = moment.utc(`${d} ${hourStart}:00`).unix()
            }

            let stop_over = true
            if (stopOverMode && point.type === 4) stop_over = false

            return {
              lat: point.address.lat.trim(),
              lon: point.address.lon.trim(),
              waittime: point.routeWaittime ? point.routeWaittime + '' : 0,
              is_anchor: point.isAnchor,
              time: time || null,
              curbside_pickup: curbside_pickup,
              type: point.type,
              stop_over: stop_over,
            }
          })

          let set = {
            curbside_pickup: data.curbside_pickup,
            no_uturns: data.no_uturns,
            speed_multiplier: data.speed_multiplier,
          }

          if (data.use_default_rules && defSettings) {
            set.curbside_pickup = defSettings.company.curbside_pickup
            set.no_uturns = defSettings.company.no_uturns
          }
          let send = {
            points: points,
            steps: 'true',
            no_uturns: set.no_uturns,
            speed_multiplier: set.speed_multiplier,
            new_routing: defSettings ? defSettings.company.new_routing : 0,
            curbside_pickup: set.curbside_pickup,
            ...(host && { host }),
          }

          const geoResponse = await axios.post(localUrlGeo, send)
          if (geoResponse.status === 200) {
            data.osrm = geoResponse.data
          } else {
            console.warn('OSRM ERROR')
          }
          dispatch({
            type: types.ROUTE_GET_DETAILS,
            payload: data,
          }).catch(() => {
            if (geoResponse.status === 400) {
              data.osrm = {}

              dispatch({
                type: types.ROUTE_GET_DETAILS,
                payload: data,
              })
            }
          })
        } else {
          dispatch({
            type: types.ROUTE_GET_DETAILS,
            payload: data,
          })
          dispatch({
            type: types.ROUTE_GET_DETAILS_LOADING,
            payload: false,
          })
        }
      } else {
        dispatch({
          type: types.ROUTE_GET_DETAILS_LOADING,
          payload: false,
        })
        throw new Error('error in getRunList')
      }
    } catch (error) {
      console.warn(error)
    } finally {
      dispatch({
        type: types.ROUTE_GET_DETAILS_LOADING,
        payload: false,
      })
      dispatch({ type: commonTypes.PENDING_FINISH })
    }
  }
}

// получаю всю информацию по маршруту , собираю вэйпоинты, получаю OSRM
export function getRunDetailsGPS(runId, defSettings, steps = 'false') {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.get(
      `/routes/${runId}?expand=points.routePointChildren.childaddress,points.routePointChildren.childaddress.child,points.routePointRoute,points.routePointTransferChild.childAddress.child`
    )
      .then((response) => {
        if (response.status === 200) {
          let data = response.data
          if (data.points.length >= 2) {
            let pointsSort = _.sortBy(data.points, 'position')
            let Data = _.filter(pointsSort, (value) => {
              if (value.address && value.address.lat) {
                return value
              }
            })

            let some = Data.some((x) => x.isAnchor)
            let points = Data.map((point, i) => {
              let curbside_pickup
              if (point.defaultSettings) {
                curbside_pickup = data.curbside_pickup
              } else {
                curbside_pickup = point.curbside_pickup
              }

              let time = 0
              if (point.isAnchor) {
                let d = moment().format('YYYY-MM-DD')
                time = moment.utc(`${d} ${point.routeTime}`).unix()
              }

              if (!some && !i) {
                let d = moment().format('YYYY-MM-DD')
                time = moment.utc(`${d} ${7}:00`).unix()
              }

              return {
                lat: point.address.lat,
                lon: point.address.lon,
                waittime: point.routeWaittime ? point.routeWaittime + '' : 0,
                is_anchor: point.isAnchor,
                time: time || null,
                curbside_pickup: curbside_pickup,
                type: point.type,
                // stopover: stopover
              }
            })

            let set = {
              curbside_pickup: data.curbside_pickup,
              no_uturns: data.no_uturns,
              speed_multiplier: data.speed_multiplier,
            }

            if (data.use_default_rules && defSettings) {
              set.curbside_pickup = defSettings.curbside_pickup
              set.no_uturns = defSettings.no_uturns
            }
            let send = { points: points, steps: 'true', ...set }
            axios
              .post(localUrlGeo, send)
              .then((response) => {
                if (response.status === 200) {
                  data.osrm = response.data
                } else {
                  console.warn('OSRM ERROR')
                }
                dispatch({
                  type: types.ROUTE_GET_DETAILS_GPS,
                  payload: data,
                })
                dispatch({ type: commonTypes.PENDING_FINISH })
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            dispatch({
              type: types.ROUTE_GET_DETAILS_GPS,
              payload: data,
            })
          }
        } else {
          throw new Error('error in getRunList')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function getOSRM(id, points, trip, run, steps = 'false') {
  return function (dispatch) {
    dispatch({
      type: types.OSRM,
    })
  }
}

export function positionFn(state, routeId, position) {
  let res = null
  let group = {}
  state.routesView.routes.forEach((gr) => {
    gr.routes.forEach((x) => {
      if (x.id === routeId) {
        res = (x.points || []).find(
          (y) => y.routePointRoute.view_position === position * 1
        )
        group = x
      }
    })
  })
  if (res) {
    return res.position
  } else {
    return group.points.length
  }
}

// редактирование позиции и времени
export function editPoints(
  id,
  position,
  routeId,
  routeWaittime,
  busStopTitle,
  address
) {
  return function (dispatch, state) {
    dispatch({ type: commonTypes.PENDING_START })
    let getState = state()
    let posReal = positionFn(getState, routeId, position)
    rq.put(`route-points/${id}?expand=routePointRoute`, {
      position: posReal,
      routeId: routeId,
      routeWaittime: routeWaittime,
      busStopTitle: busStopTitle,
      address: address,
    }).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: types.ROUTE_EDIT,
          payload: response.data,
          runId: routeId,
          position: position,
        })
        dispatch({ type: commonTypes.PENDING_FINISH })
      } else {
        console.warn('error')
        dispatch({ type: commonTypes.PENDING_END })
      }
    })
  }
}

// редактирование настроек и времени
export function editPointsSett(data, secondRun, optimized = false) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.put(
      `route-points/${data.id}?expand=routePointRoute`,
      JSON.stringify(data)
    ).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: types.ROUTE_EDIT,
          payload: response.data,
          runId: data.routeId,
          secondRun: secondRun,
        })
        dispatch({ type: commonTypes.PENDING_FINISH })
      } else {
        console.warn('error')
        dispatch({ type: commonTypes.PENDING_END })
      }
    })
  }
}

export function SumDuration(route, index, Wait, time) {
  if (route) {
    return {
      type: types.SUM_DURATION,
      payload: { Route: route, Index: index, Wait: Wait, time: time },
    }
  }
}

export function addInRun(item, school) {
  // school передаётся только в случает ребенок вместе со школой
  return function (dispatch) {
    run
      .post(`/route-points`, JSON.stringify(item))
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: item.routeId,
          })
          if (school) {
            run
              .post(`/route-points`, JSON.stringify(school))
              .then((response) => {
                if (response.status === 201) {
                  dispatch({
                    type: types.RUN_ADD_ITEM,
                    payload: response.data,
                    runId: item.routeId,
                  })
                }
              })
          }
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function cloneRun(id, name, reverseType, anchorTime) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    let data = JSON.stringify({
      id: id,
      name: name,
      reverse: reverseType,
      anchor: anchorTime,
      expand:
        'points.routePointChildren.childaddress,points.routePointRoute,points,riderscount,stopscount',
    })
    run.post(`/routes/copy`, data).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: types.ROUTE_ADD_ITEM,
          payload: response.data,
          runId: id,
        })
        dispatch({ type: commonTypes.PENDING_FINISH })
      }
    })
  }
}

export function delPosition(pointId, position, runId, transferPoints) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    run
      .delete(`/route-points/${pointId}`)
      .then((response) => {
        if (response.status === 204) {
          if (transferPoints.length > 1) {
            let route_id = (transferPoints || []).find(
              (x) => x.route_id !== runId
            ).route_id

            dispatch({
              type: types.RUN_RELOAD2,
              runId: route_id,
            })
          }

          dispatch({
            type: types.RUN_DELETE_POSITION,
            payload: pointId,
            runId: runId,
          })
        } else {
          throw new Error('error in del position')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function delChildFromTranfer(data, runId) {
  return function (dispatch) {
    run
      .delete(
        `/route-points/remove-child?id=${data.id}&child_address_id=${data.child_address_id}`
      )
      .then((response) => {
        if (response.status === 204) {
          dispatch({
            type: types.RUN_DELETE_FROM_TRANSFER,
            payload: data,
            runId: runId,
          })
        } else {
          throw new Error('error in delChildFromStop')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function delChildFromStop(data, runId) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    run
      .delete(
        `/route-points/remove-child?id=${data.id}&child_address_id=${data.child_address_id}`
      )
      .then((response) => {
        if (response.status === 204) {
          dispatch({
            type: types.RUN_DELETE_FROM_BUS,
            payload: data,
            runId: runId,
            isSchool: data.type === 2,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          throw new Error('error in delChildFromStop')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function childInTransfer(data) {
  let { routeId } = data
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/add-child-point-in-transfer`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_DELETE_POSITION,
            runId: routeId,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          throw new Error('error in childInBus')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function childInTransferReLoad(route_id) {
  return function (dispatch) {
    dispatch({
      type: types.RUN_DELETE_POSITION,
      runId: route_id,
    })
  }
}

export function childInBus(data) {
  let { routeId, childPointId } = data
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/move`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_DELETE_POSITION,
            payload: childPointId,
            runId: routeId,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          throw new Error('error in childInBus')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function runReload(route_id) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    dispatch({
      type: types.RUN_RELOAD,
      runId: route_id,
    })
    dispatch({ type: commonTypes.PENDING_FINISH })
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
export function stopsListToRun(data, route_id) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    rq.post(`route-points/create-bus-stop-multiple`, JSON.stringify(data))
      .then((response) => {
        if (response.status === 200) {
          sleep(1000)
          dispatch({
            type: types.RUN_RELOAD,
            runId: route_id,
          })
        } else {
          throw new Error('error in stops to run')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function childrenInStop(data) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    document.body.classList.add('loading')
    run
      .post(`/route-points/add-children`, data)
      .then((response) => {
        document.body.classList.remove('loading')
        if (response.status === 200) {
          dispatch({
            type: types.RUN_RELOAD,
            runId: data.route_id,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          throw new Error('error in childInBus')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function childrenInStopV2(data) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    document.body.classList.add('loading')
    runV2
      .post(`/route/point/add-children`, data)
      .then((response) => {
        document.body.classList.remove('loading')
        if (response.status === 200) {
          dispatch({
            type: types.RUN_RELOAD,
            runId: data.routeId,
          })
        } else {
          throw new Error('error in childInBus')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function addRidersInRun(data) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    document.body.classList.add('loading')
    run
      .post(`/route-points/create-child-some`, data)
      .then((response) => {
        document.body.classList.remove('loading')
        if (response.status === 200) {
          dispatch({
            type: types.RUN_RELOAD,
            runId: data.route_id,
          })
        } else {
          throw new Error('error in childInBus')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        document.body.classList.remove('loading')
        console.warn(error)
      })
  }
}

export function addressInStop(data) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/add-child`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_RELOAD,
            runId: data.routeId,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          throw new Error('error in childInBus')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function editAnchor(data) {
  let sendData = {
    position: data.position,
    routeId: data.routeId,
    isAnchor: data.isAnchor,
    routeTime: data.time ? moment.utc(data.time, 'h:mm A').format('HH:mm') : '',
    routeWaittime: data.waittime,
  }

  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    run
      .put(`/route-points/${data.id}?expand=routePointRoute`, sendData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_EDIT_ANCHOR,
            payload: response.data,
            runId: data.routeId,
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          dispatch({ type: commonTypes.PENDING_END })
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: commonTypes.PENDING_END })
      })
  }
}

export function changePosition(data) {
  let { point_id, position, route_id } = data
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    run
      .put(`/route-points/${point_id}?expand=routePointRoute`, {
        position: position,
        routeId: route_id,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_CHANGE_POSITION,
            payload: [response.data, data],
          })
          dispatch({ type: commonTypes.PENDING_FINISH })
        } else {
          throw new Error('error in changePosition')
          dispatch({ type: commonTypes.PENDING_END })
        }
      })
      .catch((error) => {
        console.warn(error)
        dispatch({ type: commonTypes.PENDING_END })
      })
  }
}

export function riderInRun(item, school) {
  // school передаётся только в случает ребенок вместе со школой
  return function (dispatch, getState) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/child`, item)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: item.routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function busStopInRun(item) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    document.body.classList.add('loading')
    runV2
      .post(`/route/point/bus-stop`, item)
      .then((response) => {
        document.body.classList.remove('loading')
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: item.routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function busStopForGroupedPins(data) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    document.body.classList.add('loading')
    runV2
      .post(`/route/point/bus-stops`, data)
      .then((response) => {
        document.body.classList.remove('loading')
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: data[0].routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function redirectionPointInRun(item) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/redirection`, item)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: item.routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function schoolInRun(item) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/school`, item)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: item.routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function clearEror() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_ERROR,
    })
  }
}

export function depotInRun(item) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/depot`, item)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM,
            payload: response.data,
            runId: item.routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function transferInRun(item) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .post(`/route/point/transfer`, item)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.RUN_ADD_ITEM_TRANSFER,
            payload: response.data,
            runId: item.routes[0].routeId,
          })
        } else {
          throw new Error('error in add item')
        }
      })
      .catch((error) => {
        dispatch({ type: commonTypes.PENDING_END })
        console.warn(error)
      })
  }
}

export function getAddressGeo(item, onSuccess) {
  return function (dispatch) {
    run
      .get(`/routes/geocode?address=${item}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.lat === '' && response.data.lon === '') {
            dispatch({
              type: types.ADDRESS_GEOCODE,
              payload: { errorMessage: 'Address is incorrect' },
            })
          } else {
            dispatch({
              type: types.ADDRESS_GEOCODE,
              payload: response.data,
            })
            onSuccess()
          }
        } else {
          throw new Error('Error in get geocode')
        }
      })
      .catch((error) => {
        dispatch({
          type: types.ADDRESS_GEOCODE,
          payload: { errorMessage: error },
        })
        console.warn(error)
      })
  }
}

export function setGeoAddressResponse() {
  return function (dispatch) {
    dispatch({
      type: types.ADDRESS_GEOCODE,
      payload: {},
    })
  }
}

export function isConvertibleToNumber(string) {
  return !isNaN(parseFloat(string))
}
